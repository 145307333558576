import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import CorporateSVG from '../svg/CorporateSVG'

const Corporate = () => {
  return (
    <div>
      <Container className='py-5'>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1>Our Corporate Values</h1>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <div className='my-5'>
              <CorporateSVG />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default Corporate
