import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import HireForm from '../components/forms/HireForm'
import PartnerForm from '../components/forms/PartnerForm'
import BackgroundImage from '../images/talents.jpg'

const TalentsScreen = () => {
  const [showForm, setShowForm] = useState(false)
  const [showPForm, setShowPForm] = useState(false)

  const ShowForm = () => {
    setShowForm(!showForm)
    setShowPForm(false)
  }
  const ShowPForm = () => {
    setShowPForm(!showPForm)
    setShowForm(false)
  }
  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.8,
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      scale: 1.2,
    },
  }

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.8,
  }
  return (
    <motion.div
      exit='out'
      animate='in'
      initial='initial'
      variants={pageVariants}
      transition={pageTransition}
    >
      <div
        className='background-image d-flex align-items-center'
        style={{
          backgroundImage: 'url(' + BackgroundImage + ')',
          height: '100vh',
          backgroundSize: 'cover',
        }}
      >
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <div className='mb-5'>
                <h1>Join Our Team</h1>
                <p className='pb-3'>
                  Talent hits a target no one else can hit. Can you?
                </p>
                <motion.button
                  whileHover={{
                    scale: 1.1,
                  }}
                  whileTap={{ scale: 0.8 }}
                  className='talent-button'
                  onClick={ShowForm}
                >
                  Apply Now
                </motion.button>
              </div>
              <div>
                <h1>Become a Partner</h1>
                <p className='pb-3'>
                  Do you have a creative idea and seek the right collaboration?
                </p>
                <motion.button
                  whileHover={{
                    scale: 1.1,
                  }}
                  whileTap={{ scale: 0.8 }}
                  className='talent-button'
                  onClick={ShowPForm}
                >
                  Let's Connect
                </motion.button>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              {showForm && <HireForm />}
              {showPForm && <PartnerForm />}
            </Col>
          </Row>
        </Container>
      </div>
    </motion.div>
  )
}

export default TalentsScreen
