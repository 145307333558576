import React from 'react'
import CarouselHome from '../../components/home/CarouselHome'
import { Row, Col, Container } from 'react-bootstrap'
import Text from '../../images/icons/custom.svg'
import './Home.css'
const Solutions = () => {
  return (
    <div style={{ backgroundColor: 'black' }}>
      <Container className='creative py-5'>
        <Row className='d-flex align-items-center'>
          <Col
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className='d-flex justify-content-center'
          >
            <img src={Text} alt='custom' style={{ width: '60%' }} />
          </Col>
          <Col>
            <div className='greenbackground'>
              <CarouselHome />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Solutions
