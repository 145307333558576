import { motion } from 'framer-motion'
import React from 'react'
import { Form, FormGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import './Form.css'
import * as FiIcons from 'react-icons/fi'

const HireForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const onSubmit = (data, e) => {
    e.target.reset()
    console.log(watch())
  }
  return (
    <Form className='request-form' onSubmit={handleSubmit(onSubmit)}>
      <h1 className='py-3'>Join Our Team</h1>
      <FormGroup controlId='formName'>
        <Form.Control
          {...register('name', { required: true })}
          type='text'
          placeholder='Full Name'
        />
      </FormGroup>
      <FormGroup controlId='formEmail'>
        <Form.Control
          {...register('email', { required: true })}
          type='text'
          placeholder='Email'
        />
      </FormGroup>
      <FormGroup controlId='formMobile'>
        <Form.Control
          {...register('mobile', { required: true })}
          type='number'
          placeholder='Mobile'
        />
      </FormGroup>
      <FormGroup
        style={{ paddingLeft: '5%' }}
        className='d-flex align-items-center'
      >
        <FiIcons.FiPlus color='white' />
        <p style={{ color: 'white', marginBottom: '0', paddingLeft: '3%' }}>
          Attach File
        </p>
      </FormGroup>
      {errors.name && errors.email && errors.mobile && (
        <p>These Fields is required!</p>
      )}
      <motion.button
        type='submit'
        whileHover={{
          scale: 1.1,
        }}
        whileTap={{ scale: 0.8 }}
        className='talent-button'
      >
        SEND
      </motion.button>
    </Form>
  )
}

export default HireForm
