import React from 'react'
import BackgroundImage from '../images/Clients-1.jpg'
import { Container, Row, Col } from 'react-bootstrap'
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { motion } from 'framer-motion'
import Clients from '../data/Clients'
import ClientsCard from '../components/clients/ClientsCard'
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

const ClientsScreen = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.8,
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      scale: 1.2,
    },
  }

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.8,
  }
  return (
    <motion.div
      exit='out'
      animate='in'
      initial='initial'
      variants={pageVariants}
      transition={pageTransition}
    >
      <div
        className='clients background-image d-flex align-items-center'
        style={{
          backgroundImage: 'url(' + BackgroundImage + ')',
          backgroundSize: 'cover',
          paddingTop: '10%',
          paddingBottom: '10%',
        }}
      >
        <Container className='px-0 '>
          <Container>
            <Row className='py-5'>
              <Col lg={12} md={12} sm={12} xs={12}>
                <h1>Our Clients</h1>
              </Col>
              <Col lg={12} md={12} sm={12} xs={12}>
                <p>
                  Driven by our passion to craft trendsetting, pragmatic,
                  heartfelt corporate presents, promotional gifts, and medical
                  accessories.
                </p>
              </Col>
            </Row>
          </Container>
          <h4 className='pt-5'>Medical Accessories Clients</h4>
          <Row className='text-center py-5'>
            {Clients.map((data) => (
              <Col lg={2} md={2} className='pr-2 pb-5'>
                <ClientsCard data={data} />
              </Col>
            ))}
          </Row>
          <h4>Marketing & Promotional Items Clients</h4>
          <Row className='text-center py-5'>
            {Clients.map((data) => (
              <Col lg={2} md={2} className='pr-2 pb-5'>
                <ClientsCard data={data} />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </motion.div>
  )
}

export default ClientsScreen
