import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import BackgroundImageThird from '../../images/ouredge.jpg'
import Thumbs from '../../images/icons/thumb.svg'
import Medal from '../../images/icons/medal.svg'
import Shake from '../../images/icons/shake.svg'
import Bulb from '../../images/icons/bulb.svg'
import './Home.css'

const Edge = () => {
  return (
    <div
      style={{
        backgroundImage: 'url(' + BackgroundImageThird + ')',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        width: '100%',
        backgroundSize: 'cover',
      }}
      className='d-flex align-items-center'
    >
      <Container className='our-edge'>
        <Row>
          <Col>
            <Container className='py-5'>
              <h1>Our Edge</h1>
              <Row className='text-center py-5'>
                <Col>
                  <div className='hovereffect d-flex align-items-center'>
                    <Image width='100%' src={Thumbs} />
                  </div>
                  <div style={{ height: '50px' }} className='mt-4'>
                    <h6>Committed to excellence</h6>
                  </div>
                  <div>
                    <p>
                      The only Shortcuts we offer are the ones that lead to your
                      success.
                    </p>
                  </div>
                </Col>
                <Col>
                  <div className='hovereffect d-flex align-items-center'>
                    <Image width='100%' src={Shake} />
                  </div>
                  <div style={{ height: '50px' }} className='mt-4'>
                    <h6>Pledge premium designer products</h6>
                  </div>
                  <div>
                    <p>Quality is our top priority.</p>
                  </div>
                </Col>
                <Col>
                  <div className='hovereffect d-flex align-items-center'>
                    <Image width='100%' src={Medal} />
                  </div>
                  <div style={{ height: '50px' }} className='mt-4'>
                    <h6>Leaders in innovation</h6>
                  </div>
                  <div>
                    <p>we create unconventional, sustainable solutions.</p>
                  </div>
                </Col>
                <Col>
                  <div className='hovereffect d-flex align-items-center'>
                    <Image width='100%' src={Bulb} />
                  </div>
                  <div style={{ height: '50px' }} className='mt-4'>
                    <h6>Value our clients & suppliers</h6>
                  </div>
                  <div>
                    <p>
                      We are keen on building and maintaining strong
                      relationships with our stakeholders.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Edge
