import React from 'react'
import Logo from '../../images/animations/Logo.svg'
import BackgroundImage from '../../images/landing.jpg'
import { Row, Col } from 'react-bootstrap'
import './Home.css'
const Landing = () => {
  return (
    <Row className='mr-0 ml-0' style={{ width: '100%' }}>
      <Col className='p-0'>
        <div
          className='background-image d-flex justify-content-center align-content-center'
          style={{
            backgroundImage: 'url(' + BackgroundImage + ')',
            height: '100vh',
            width: '100%',
            backgroundSize: 'cover',
          }}
        >
          <div className='w-100 d-flex justify-content-center align-items-center'>
            <div className='green-banner d-flex justify-content-center align-items-center'>
              <div className='main-title' data-text='SHORTCUTS'>
                <object data={Logo} aria-labelledby='label1' />
                <div id='parent' className='mt-5'>
                  « We are not simple traders, we are solutions providers »
                  <div id='border'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default Landing
