import { motion } from 'framer-motion'
import React from 'react'
import * as FaIcons from 'react-icons/fa'

const WhatsAppButton = () => {
  return (
    <motion.button
      className='whatsapp'
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.8 }}
    >
      <FaIcons.FaWhatsapp fontSize='25' />
    </motion.button>
  )
}

export default WhatsAppButton
