import React, { useState } from 'react'
import './Clients.css'

const ClientsCard = ({ data }) => {
  const [hovered, setHovered] = useState(false)

  return (
    <>
      <div
        className='clientImage d-flex'
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <img src={data.url} alt='' className='image' />
      </div>
      <div class='client-details py-3'>
        {hovered && (
          <div>
            <p>{data.industry}</p>
            <p>{data.location}</p>
          </div>
        )}
      </div>
    </>
  )
}

export default ClientsCard
