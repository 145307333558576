import { useState, useEffect } from 'react'
import Header from './components/Header'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import HomeScreen from './screens/HomeScreen'
import AboutScreen from './screens/AboutScreen'
import ProductsScreen from './screens/ProductsScreen'
import Footer from './components/Footer'
import ClientsScreen from './screens/ClientsScreen'
import TalentsScreen from './screens/TalentsScreen'
import ContactScreen from './screens/ContactScreen'
import AnimatedCursor from 'react-animated-cursor'
import WhatsAppButton from './components/WhatsAppButton'
import MediaScreen from './screens/MediaScreen'

function App() {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 4500)
  }, [])

  return (
    <div className='App'>
      {loading ? (
        <div
          loading='true'
          style={{
            backgroundColor: 'black',
            height: '100vh',
            width: '100%',
            backgroundSize: 'cover',
          }}
          className='d-flex justify-content-center align-items-center'
        >
          <div
            id='animation_container'
            style={{
              backgroundColor: 'rgba(0, 0, 0, 1.00)',
              width: '800px',
              height: '600px',
            }}
          >
            <canvas
              id='canvas'
              width='800'
              height='600'
              style={{
                position: 'absolute',
                display: 'block',
                backgroundColor: 'rgba(0, 0, 0, 1.00)',
              }}
            ></canvas>
            <div
              id='dom_overlay_container'
              style={{
                pointerEvents: 'none',
                overflow: 'hidden',
                width: '800px',
                height: '600px',
                position: 'absolute',
                left: '0px',
                top: '0px',
                display: 'block',
              }}
            ></div>
          </div>
        </div>
      ) : (
        <Router>
          <Header />
          <AnimatedCursor
            innerSize={8}
            outerSize={8}
            color='57, 169, 74'
            outerAlpha={0.2}
            innerScale={0.7}
            outerScale={5}
          />
          <WhatsAppButton />
          <main>
            <Route path='/' component={HomeScreen} exact />
            <Route path='/about' component={AboutScreen} />
            <Route path='/products' component={ProductsScreen} />
            <Route path='/clients' component={ClientsScreen} />
            <Route path='/talents' component={TalentsScreen} />
            <Route path='/contact' component={ContactScreen} />
            <Route path='/media' component={MediaScreen} />
          </main>
          <Footer />
        </Router>
      )}
    </div>
  )
}

export default App
