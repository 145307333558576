import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import './Home.css'
import WhoWeAreImage from '../../images/who-we-are.jpg'

const WhoWeAre = () => {
  return (
    <div
      style={{
        backgroundImage: 'url(' + WhoWeAreImage + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100%',
      }}
      className='d-flex align-items-center'
    >
      <Container className='py-5 whoweare'>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1>Who we are?</h1>
          </Col>
        </Row>
        <Row className='pt-5'>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h5>We Are Solution Providers</h5>
            <p>
              Shortcuts is a regional marketing agency based in Beirut that
              specializes in the "Promo with Purpose" and "Art of Gifting"
              principles. Shortcuts creates, manufactures, supplies, and
              distributes promotional goods, corporate presents, and medical
              accessories. At Shortcuts, we add immense experience and
              innovation to the B2B market as an official agent for several
              international brands. Shortcuts specializes in the supply and
              sourcing of marketing/promotional products, as well as medical
              accessories and personal protective equipment (PPE). With the
              highest logistics and supply chain standards, our elevated
              services ensure timely deliveries. With an “outside the box”
              mindset, we respond to multicultural markets, incorporate
              innovative technologies and the finest industry practices, thereby
              elevating your brand positioning with an unconventional approach.
              By elegantly personalizing your promotional offerings, we
              guarantee consistent brand visibility to your target audience in
              an undeniably enticing manner.
            </p>
            <p>
              « We believe creative designs leave a long-lasting imprint on
              identity »
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default WhoWeAre
