import React from 'react'
import { Carousel, Image } from 'react-bootstrap'
import Item1 from '../../images/carousel/mo6218_03_print.png'
import Item2 from '../../images/carousel/mo9987_03.png'

const CarouselHome = () => {
  return (
    <Carousel indicators={false} fade>
      <Carousel.Item>
        <span>
          <Image src={Item1} className='d-block w-100' />
        </span>
      </Carousel.Item>
      <Carousel.Item>
        <span>
          <Image src={Item2} className='d-block w-100' />
        </span>
      </Carousel.Item>
    </Carousel>
  )
}

export default CarouselHome
