import { motion } from 'framer-motion'
import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import MediaImage from '../images/media.jpg'
import MediaImageThumb from '../images/media-thumbnail.jpg'

const MediaScreen = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.8,
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      scale: 1.2,
    },
  }

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.8,
  }
  return (
    <motion.div
      exit='out'
      animate='in'
      initial='initial'
      variants={pageVariants}
      transition={pageTransition}
      className='media'
    >
      <Container>
        <Row style={{ paddingTop: '10%' }}>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1>
              Gallery <span>Browse our elite selection of items</span>
            </h1>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <img alt='media' src={MediaImage} className='py-5 w-100' />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12} className='pb-5'>
            <h1>
              News <span>Stay tuned for our latest news and updates</span>
            </h1>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <img alt='media' src={MediaImageThumb} className='w-100' />
          </Col>
          <Col
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className='media-paragraph d-flex align-items-center'
          >
            <div>
              <p>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor
                in hendrerit in vulputate velit esse molestie consequ
              </p>
              <span>Read More</span>
            </div>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12} className='py-5'>
            <h1>
              Catalogues <span>Discover our range of products and brands</span>
            </h1>
          </Col>
          <Row className='pb-5 products'>
            <Col lg={4} md={4} sm={12} xs={12} className='pb-5'>
              <div className='text-center'>
                <Image
                  src='https://dummyimage.com/250x250/ffffff/fff'
                  roundedCircle
                />
                <h5>Promotional Items</h5>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <div className='text-center'>
                <Image
                  src='https://dummyimage.com/250x250/ffffff/fff'
                  roundedCircle
                />
                <h5>Corporate Gifts</h5>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <div className='text-center'>
                <Image
                  src='https://dummyimage.com/250x250/ffffff/fff'
                  roundedCircle
                />
                <h5>Product Designs</h5>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <div className='text-center'>
                <Image
                  src='https://dummyimage.com/250x250/ffffff/fff'
                  roundedCircle
                />
                <h5>Sustainable Products</h5>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <div className='text-center'>
                <Image
                  src='https://dummyimage.com/250x250/ffffff/fff'
                  roundedCircle
                />
                <h5>Stand Designs</h5>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <div className='text-center'>
                <Image
                  src='https://dummyimage.com/250x250/ffffff/fff'
                  roundedCircle
                />
                <h5>Leather Designs</h5>
              </div>
            </Col>
          </Row>
        </Row>
      </Container>
    </motion.div>
  )
}

export default MediaScreen
