import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import './Home.css'
import Clients from '../../data/Clients'
import ClientsCard from '../clients/ClientsCard'

const ClientsLanding = () => {
  return (
    <div className='clients' style={{ backgroundColor: 'black' }}>
      <Container className='py-5'>
        <h1>Our clients</h1>
        <p>
          Driven by our passion to craft trendsetting, pragmatic, heartfelt
          corporate presents, promotional gifts, and medical accessories.
        </p>
        <h4 className='pt-5'>Medical Accessories Clients</h4>
        <Row className='text-center py-5'>
          {Clients.map((data) => (
            <Col lg={2} md={2} className='pr-2 pb-5'>
              <ClientsCard data={data} />
            </Col>
          ))}
        </Row>
        <h4>Marketing & Promotional Items Clients</h4>
        <Row className='text-center py-5'>
          {Clients.map((data) => (
            <Col lg={2} md={2} className='pr-2 pb-5'>
              <ClientsCard data={data} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default ClientsLanding
