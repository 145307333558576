import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import * as AiIcons from 'react-icons/ai'

const Trust = () => {
  return (
    <div>
      <Container className='pb-5 trust'>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className='py-5'>Trusted by the Best</h1>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <p>
              «SHORTCUTS team is quite dynamic, responsive, passionate and they
              can get really creative. It is a pleasure to work with Halim and
              the team. SHORTCUTS fresh ideas gave our corporate gifts a new
              edge.»
            </p>
            <p>Abed Hallak</p>
            <p>Senior Manager Marketing SITA Regional</p>
            <div className='stars pb-5'>
              <AiIcons.AiFillStar color='#47ae56' />
              <AiIcons.AiFillStar color='#47ae56' />
              <AiIcons.AiFillStar color='#47ae56' />
              <AiIcons.AiFillStar color='#47ae56' />
              <AiIcons.AiFillStar color='#47ae56' />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Trust
