import React from 'react'
import BackgroundImage from '../../images/our-story.jpg'
import { Container, Row, Col } from 'react-bootstrap'
import './Story.css'

const Landing = () => {
  return (
    <div
      className='background-image d-flex justify-content-center align-content-center'
      style={{
        backgroundImage: 'url(' + BackgroundImage + ')',
        height: '100vh',
        width: '100%',
        backgroundSize: 'cover',
      }}
    >
      <Container className='our-story d-flex align-items-end'>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1>Our Story</h1>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <p>
              Having evolved the concept of promotional, corporate gifting and
              medical accessories, Shortcuts has become a catalyst of product
              innovation and design. Founded in 2015, Shortcuts established a
              new edge to the marketing solutions culture in the region.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Landing
