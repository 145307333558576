import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Map from '../images/Map-01.svg'
import * as Io5Icons from 'react-icons/io5'
import * as ImIcons from 'react-icons/im'
import * as FaIcons from 'react-icons/fa'
import RequestForm from '../components/forms/RequestForm'

const ContactScreen = () => {
  const [showForm, setShowForm] = useState(false)

  const ShowForm = () => {
    setShowForm(!showForm)
  }
  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.8,
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      scale: 1.2,
    },
  }

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.8,
  }
  return (
    <motion.div
      exit='out'
      animate='in'
      initial='initial'
      variants={pageVariants}
      transition={pageTransition}
    >
      <Container className='py-5'>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Image src={Map} />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12} className='text-center'>
            <motion.button
              whileHover={{
                scale: 1.1,
              }}
              whileTap={{ scale: 0.8 }}
              className='talent-button'
              onClick={ShowForm}
            >
              REQUEST A QUOTE
            </motion.button>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12} className='text-center'>
            {showForm && <RequestForm />}
          </Col>
        </Row>
        <Row className='py-5'>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1 className='text-center'>Let's Talk</h1>
          </Col>
        </Row>
        <Row className='text-center'>
          <Col lg={4} md={4} sm={12} xs={12}>
            <div>
              <motion.div className='social-icons'>
                <Io5Icons.IoLocationSharp fill='white' fontSize='40' />
              </motion.div>
              <div className='py-5'>
                <p style={{ marginBottom: '0' }}>Ashrafiyeh Ghabi 77 street,</p>
                <p style={{ marginBottom: '0' }}>Retro Sioufi, G floor,</p>
                <p style={{ marginBottom: '0' }}>P.O. Box 16 – 6462,</p>
                <p style={{ marginBottom: '0' }}>Beirut, Lebanon</p>
              </div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <motion.div className='social-icons'>
              <ImIcons.ImPhone fill='white' fontSize='40' />
            </motion.div>
            <div className='py-5'>
              <p style={{ marginBottom: '0' }}>+961 1 204 011 </p>
              <p style={{ marginBottom: '0' }}>+961 71 212 320</p>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <motion.div className='social-icons'>
              <FaIcons.FaEnvelope fill='white' fontSize='40' />
            </motion.div>
            <div className='py-5'>
              <p style={{ marginBottom: '0' }}>info@shortcuts-me.com</p>
              <p style={{ marginBottom: '0' }}>query@shortcuts-me.com</p>
            </div>
          </Col>
        </Row>
      </Container>
    </motion.div>
  )
}

export default ContactScreen
