import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const Mission = () => {
  return (
    <div className='py-5 mission'>
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>
            <h1>Our Mission</h1>
            <p>
              We aim to provide uniquely crafted, premium promotional, corporate
              gifting solutions, and medical accessories that combine
              creativity, performance, and value.
            </p>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <h1>Our Vision</h1>
            <p>
              To become the leading one-stop hub for creative corporate gifting,
              marketing solutions and medical accessories.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Mission
