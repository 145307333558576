import React from 'react'

import Landing from '../components/home/Landing'
import Solutions from '../components/home/Solutions'
import WhoWeAre from '../components/home/WhoWeAre'
import Edge from '../components/home/Edge'
import ClientsLanding from '../components/home/ClientsLanding'
import { motion } from 'framer-motion'
import Trust from '../components/home/Trust'

const HomeScreen = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.8,
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      scale: 1.2,
    },
  }

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.8,
  }
  return (
    <motion.div
      exit='out'
      animate='in'
      initial='initial'
      variants={pageVariants}
      transition={pageTransition}
    >
      <Landing />
      <Solutions />
      <WhoWeAre />
      <Edge />
      <ClientsLanding />
      <Trust />
    </motion.div>
  )
}

export default HomeScreen
