import React, { useState } from 'react'
import { Col, Container, Row, Image } from 'react-bootstrap'

const Teams = () => {
  const [show, setShow] = useState(true)
  const [show1, setShow1] = useState(true)
  const [show2, setShow2] = useState(true)
  const mouseOver1 = () => {
    setShow(false)
  }
  const mouseOut1 = () => {
    setShow(true)
  }
  const mouseOver2 = () => {
    setShow1(false)
  }
  const mouseOut2 = () => {
    setShow1(true)
  }
  const mouseOver3 = () => {
    setShow2(false)
  }
  const mouseOut3 = () => {
    setShow2(true)
  }
  return (
    <Container className='py-5 teams'>
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <h1>Our Team</h1>
        </Col>
        <Col lg={12} md={12} sm={12} xs={12}>
          <p>
            Shortcuts team is a handful of highly skilled customer service,
            international sales, and marketing professionals complemented by
            creative talented designers. With an extensive collective experience
            in numerous industries, our team innovates products and solutions
            that appeal to diverse markets and cultures.
          </p>
        </Col>
      </Row>
      <Row className='pt-5'>
        <Col lg={4} md={4} sm={12} xs={12}>
          <div className='img-overlay'>
            <Image
              src='https://dummyimage.com/250x250/ffffff/fff'
              roundedCircle
            />
            <div
              class='overlay'
              onMouseOver={mouseOver1}
              onMouseOut={mouseOut1}
            >
              <div class='text'>
                <h6>Halim Masrouaa</h6>
                <p>Founder & CEO</p>
              </div>
            </div>
            {show ? <h5>Halim Masrouaa</h5> : ''}
          </div>
        </Col>
        <Col lg={4} md={4} sm={12} xs={12}>
          <div className='img-overlay'>
            <Image
              src='https://dummyimage.com/250x250/ffffff/fff'
              roundedCircle
            />
            <div
              class='overlay'
              onMouseOver={mouseOver2}
              onMouseOut={mouseOut2}
            >
              <div class='text'>
                <h6>Maher Masrouaa</h6>
                <p>Employee</p>
              </div>
            </div>
            {show1 ? <h5>Maher Masrouaa</h5> : ''}
          </div>
        </Col>
        <Col lg={4} md={4} sm={12} xs={12}>
          <div className='img-overlay'>
            <Image
              src='https://dummyimage.com/250x250/ffffff/fff'
              roundedCircle
            />
            <div
              class='overlay'
              onMouseOver={mouseOver3}
              onMouseOut={mouseOut3}
            >
              <div class='text'>
                <h6>Samer Asaad</h6>
                <p>Employee</p>
              </div>
            </div>
            {show2 ? <h5>Samer Asaad</h5> : ''}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Teams
