const Clients = [
  {
    id: '0',
    url: '/images/brands/brand-1.svg',
    location: 'Lebanon',
    industry: 'Medical',
  },
  {
    id: '1',
    url: '/images/brands/brand-2.svg',
    location: 'Lebanon',
    industry: 'Medical',
  },
  {
    id: '2',
    url: '/images/brands/brand-3.svg',
    location: 'Lebanon',
    industry: 'Medical',
  },
  {
    id: '3',
    url: '/images/brands/brand-4.svg',
    location: 'Lebanon',
    industry: 'Medical',
  },
  {
    id: '4',
    url: '/images/brands/brand-5.svg',
    location: 'Lebanon',
    industry: 'Medical',
  },
  {
    id: '5',
    url: '/images/brands/brand-6.svg',
    location: 'Lebanon',
    industry: 'Medical',
  },
  {
    id: '6',
    url: '/images/brands/brand-7.svg',
    location: 'Lebanon',
    industry: 'Medical',
  },
  {
    id: '7',
    url: '/images/brands/brand-8.svg',
    location: 'Lebanon',
    industry: 'Medical',
  },
  {
    id: '8',
    url: '/images/brands/brand-9.svg',
    location: 'Lebanon',
    industry: 'Medical',
  },
  {
    id: '9',
    url: '/images/brands/brand-10.svg',
    location: 'Lebanon',
    industry: 'Medical',
  },
  {
    id: '10',
    url: '/images/brands/brand-11.svg',
    location: 'Lebanon',
    industry: 'Medical',
  },
  {
    id: '11',
    url: '/images/brands/brand-12.svg',
    location: 'Lebanon',
    industry: 'Medical',
  },
]
export default Clients
