import React, { useEffect, useRef, useState } from 'react'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import Truck from '../images/icons/truck.svg'
import './Nav.css'

const Header = () => {
  const [navBackground, setNavBackground] = useState(false)
  const navRef = useRef()
  navRef.current = navBackground

  const handleScroll = () => {
    if (window.scrollY >= 66) {
      setNavBackground(true)
    } else {
      setNavBackground(false)
    }
  }
  useEffect(() => {
    handleScroll()
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <Navbar
      className='custom-nav'
      expand='lg'
      fixed='top'
      style={
        navBackground
          ? {
              transition: '1s ease',
              background: 'black',
            }
          : { background: 'transparent' }
      }
    >
      <Container fluid>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='ml-auto'>
            <LinkContainer to='/'>
              <Nav.Link className='menu-links'>HOME</Nav.Link>
            </LinkContainer>
            <NavDropdown title='PRODUCTS' id='basic-nav-dropdown'>
              <LinkContainer to='/products'>
                <NavDropdown.Item className='menu-links'>
                  Marketing Products
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to='/products'>
                <NavDropdown.Item className='menu-links'>
                  Covid-19 Products
                </NavDropdown.Item>
              </LinkContainer>
              <LinkContainer to='/products'>
                <NavDropdown.Item className='menu-links'>
                  Digital Marketing Services
                </NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
            <LinkContainer to='/about'>
              <Nav.Link className='menu-links'>OUR STORY</Nav.Link>
            </LinkContainer>
            <LinkContainer to='/clients'>
              <Nav.Link className='menu-links'>OUR CLIENTS</Nav.Link>
            </LinkContainer>
            <LinkContainer to='/talents'>
              <Nav.Link className='menu-links'>FISHING FOR TALENTS</Nav.Link>
            </LinkContainer>
            <LinkContainer to='/media'>
              <Nav.Link className='menu-links'>MEDIA</Nav.Link>
            </LinkContainer>
            <LinkContainer to='/contact'>
              <Nav.Link className='menu-links'>CONTACT</Nav.Link>
            </LinkContainer>
            <LinkContainer to='/'>
              <div className='delivery'>
                <div>
                  <img src={Truck} alt='truck'></img>
                </div>
                <div>
                  <span>Fast Delivery Orders</span>
                </div>
              </div>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Header
