import { motion } from 'framer-motion'
import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'

const ProductsScreen = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.8,
    },
    in: {
      opacity: 1,
      x: 0,
      scale: 1,
    },
    out: {
      opacity: 0,
      scale: 1.2,
    },
  }

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.8,
  }
  return (
    <motion.div
      exit='out'
      animate='in'
      initial='initial'
      variants={pageVariants}
      transition={pageTransition}
    >
      <Container
        className='products'
        style={{ paddingTop: '10%', paddingBottom: '10%' }}
      >
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1>Marketing Products</h1>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <p style={{ width: '80%' }}>
              Shortcuts supports socio-economically vulnerable Artisans and
              small local handcraft workshops by connecting them with Local
              Ethical Enterprises (LEEs) struggling to survive in a challenging
              economic environment. LEEs provide the Artisans with fashionable
              and edgy designs to modernize their handmade products, later
              introduced by Shortcuts to well established regional and global
              businesses and corporations.
            </p>
            <p style={{ width: '80%' }}>
              Shortcuts is proud to be advocating its social responsibility by
              providing small workshops with the needed machines and know-how,
              while promoting local economic growth and preserving cultural
              heritage.
            </p>
          </Col>
        </Row>
        <Row className='pt-5'>
          <Col lg={4} md={4} sm={12} xs={12}>
            <div className='text-center'>
              <Image
                src='https://dummyimage.com/250x250/ffffff/fff'
                roundedCircle
              />
              <h3>Promotional Items</h3>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <div className='text-center'>
              <Image
                src='https://dummyimage.com/250x250/ffffff/fff'
                roundedCircle
              />
              <h3>Corporate Gifts</h3>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <div className='text-center'>
              <Image
                src='https://dummyimage.com/250x250/ffffff/fff'
                roundedCircle
              />
              <h3>Product Designs</h3>
            </div>
          </Col>
        </Row>
        <Row className='pt-5'>
          <Col lg={4} md={4} sm={12} xs={12}>
            <div className='text-center'>
              <Image
                src='https://dummyimage.com/250x250/ffffff/fff'
                roundedCircle
              />
              <h3>Sustainable Products</h3>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <div className='text-center'>
              <Image
                src='https://dummyimage.com/250x250/ffffff/fff'
                roundedCircle
              />
              <h3>Stand Designs</h3>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <div className='text-center'>
              <Image
                src='https://dummyimage.com/250x250/ffffff/fff'
                roundedCircle
              />
              <h3>Leather Designs</h3>
            </div>
          </Col>
        </Row>
      </Container>
    </motion.div>
  )
}

export default ProductsScreen
