import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import BackgroundImage from '../../images/CSR.jpg'

const CSR = () => {
  return (
    <div
      className='background-image d-flex justify-content-center align-content-center'
      style={{
        backgroundImage: 'url(' + BackgroundImage + ')',
        height: '100vh',
        width: '100%',
        backgroundSize: 'cover',
      }}
    >
      <Container className='py-5 d-flex align-items-center csr'>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12}>
            <h1>Our Corporate Social Responsibility - CSR</h1>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <p>
              Shortcuts supports socio-economically vulnerable Artisans and
              small local handcraft workshops by connecting them with Local
              Ethical Enterprises (LEEs) struggling to survive in a challenging
              economic environment. LEEs provide the Artisans with fashionable
              and edgy designs to modernize their handmade products, later
              introduced by Shortcuts to well established regional and global
              businesses and corporations.
            </p>
            <p>
              Shortcuts is proud to be advocating its social responsibility by
              providing small workshops with the needed machines and know-how,
              while promoting local economic growth and preserving cultural
              heritage.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CSR
