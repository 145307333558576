import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import FooterImage from '../images/footer.jpg'
import { LinkContainer } from 'react-router-bootstrap'
import './Nav.css'
import BluEdge from '../images/white_logo.svg'

const Footer = () => {
  return (
    <footer
      style={{
        backgroundImage: 'url(' + FooterImage + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
      }}
    >
      <Container className='py-4'>
        <Row>
          <Col
            lg={4}
            md={4}
            sm={12}
            xs={12}
            className='d-flex justify-content-center'
          >
            <div>
              <h4>Menu</h4>
              <LinkContainer to='/'>
                <p>Home</p>
              </LinkContainer>
              <LinkContainer to='/'>
                <p>Products</p>
              </LinkContainer>
              <LinkContainer to='/'>
                <p>Our Story</p>
              </LinkContainer>
              <LinkContainer to='/'>
                <p>Our Clients</p>
              </LinkContainer>
              <LinkContainer to='/'>
                <p>Our Product</p>
              </LinkContainer>
              <LinkContainer to='/'>
                <p>Fishing for talents</p>
              </LinkContainer>
              <LinkContainer to='/'>
                <p>Media</p>
              </LinkContainer>
            </div>
          </Col>
          <Col
            lg={4}
            md={4}
            sm={12}
            xs={12}
            className='social d-flex justify-content-center align-items-end'
          >
            <i className='fab fa-linkedin-in'></i>
            <i className='fab fa-facebook-f'></i>
            <i className='fab fa-instagram'></i>
          </Col>
          <Col lg={4} md={4} sm={12} xs={12}>
            <div>
              <h4>Contact Us</h4>
              <div style={{ display: 'flex' }}>
                <i className='fas fa-phone-alt'></i>
                <p>+961 1 204 011</p>
              </div>
              <div style={{ display: 'flex' }}>
                <i className='fas fa-mobile-alt'></i>
                <p> +961 71 212 320</p>
              </div>
              <div style={{ display: 'flex' }}>
                <i className='fas fa-envelope'></i>
                <p>info@shortcuts-me.com</p>
              </div>
              <div style={{ display: 'flex' }}>
                <i className='fas fa-envelope'></i>
                <p>query@shortcuts-me.com</p>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className='copy pt-2 d-flex text-center justify-content-center align-items-center'>
            <a href='https://www.bluedge.me/' rel='noreferrer' target='_blank'>
              <p className='text-center mb-0'>
                <Image src={BluEdge} /> Created & Developed by Blu Edge
              </p>
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
