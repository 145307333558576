import React, { useEffect, useState } from 'react'
import './Svg.css'

const CorporateSVG = () => {
  function showDesc(e) {
    e.target.style.opacity = '0'
    let myID = e.target.id
    document.querySelectorAll('.' + myID + '-desc').forEach(function (element) {
      element.style.opacity = '1'
    })
  }
  function hideDesc(e) {
    e.target.style.opacity = '1'
    let myID = e.target.id
    document.querySelectorAll('.' + myID + '-desc').forEach(function (element) {
      element.style.opacity = '0'
    })
  }
  return (
    <svg
      id='Layer_1'
      data-name='Layer 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1345.45 510.82'
    >
      <text
        className='cls-1'
        transform='translate(30.96 17.84)'
        id='quality-desc'
      >
        <tspan className='quality-desc'>
          We respect quality standards, implement
        </tspan>
        <tspan className='quality-desc' x='0' y='24.94'>
          extensive Quality Control measures, and
        </tspan>
        <tspan className='quality-desc' x='0' y='49.89'>
          strive to deliver frst-class products and
        </tspan>
        <tspan className='quality-desc' x='0' y='74.83'>
          services.
        </tspan>
      </text>
      <text
        className='cls-2'
        transform='translate(0 390.57)'
        id='excellence'
        onMouseOver={showDesc}
        onMouseOut={hideDesc}
      >
        Service Excellence
      </text>

      <text
        className='cls-2'
        transform='translate(343.57 449.57)'
        id='reliability'
        onMouseOver={showDesc}
        onMouseOut={hideDesc}
      >
        Reliability
      </text>
      <text
        className='cls-2'
        transform='translate(831.95 77.92)'
        id='sustain'
        onMouseOver={showDesc}
        onMouseOut={hideDesc}
      >
        Sustainability
      </text>
      <text
        className='cls-2'
        transform='translate(706.09 449.57)'
        id='integrity'
        onMouseOver={showDesc}
        onMouseOut={hideDesc}
      >
        Integrity
      </text>
      <text
        className='cls-2'
        transform='translate(1161.2 421.97)'
        id='ethics'
        onMouseOver={showDesc}
        onMouseOut={hideDesc}
      >
        Ethics
      </text>
      <path
        className='cls-3'
        d='M726.77,255.9c-6.38,6.39-13,12.45-19.74,18.44L702,278.8l-5.11,4.41q-5.1,4.41-10.38,8.62c-7,5.6-14.33,10.91-21.27,16.62s-13.68,11.68-20.33,17.74c-3.33,3-6.62,6.1-10,9.09s-6.73,6-10.13,8.92c-6.78,5.9-13.55,11.83-20.45,17.59s-13.9,11.42-21,17-14.21,11-21.5,16.35l-.26-.31c6.45-6.3,13-12.42,19.73-18.45s13.45-11.95,20.26-17.83,13.81-11.51,20.77-17.21q5.22-4.27,10.49-8.49c3.52-2.8,7.09-5.54,10.64-8.31,7.1-5.53,14.13-11.14,20.9-17.06s13.25-12.19,20-18.18q5-4.48,10.24-8.78l5.21-4.3L705,272c7-5.61,14.12-11.15,21.48-16.37Z'
        transform='translate(-10.28 -127.33)'
      />
      <path
        className='cls-3'
        d='M569.8,399.29c8.68,5.12,17.11,10.58,25.49,16.12l6.26,4.19,6.22,4.24q6.22,4.25,12.3,8.7c8.09,5.95,16,12.23,24.13,18.07s16.54,11.4,25,16.86c4.22,2.73,8.46,5.42,12.65,8.19s8.36,5.57,12.52,8.39c8.32,5.63,16.65,11.23,24.86,17s16.34,11.68,24.39,17.68,16.07,12.08,23.93,18.36l-.22.33c-8.61-5.21-17.1-10.6-25.51-16.1s-16.74-11.11-25-16.77S700.35,493,692.19,487.14c-4.08-2.93-8.16-5.86-12.2-8.84s-8-6-12.07-9c-8-6-16.13-12-24.46-17.59s-16.91-10.86-25.3-16.39c-4.18-2.78-8.33-5.61-12.41-8.53l-6.14-4.37-6.1-4.43c-8.09-5.94-16.14-12-23.94-18.33Z'
        transform='translate(-10.28 -127.33)'
      />
      <path
        className='cls-3'
        d='M723.61,252.61c8,5,15.67,10.3,23.33,15.7l5.72,4.07,5.68,4.14q5.67,4.13,11.19,8.47c7.36,5.79,14.48,11.91,21.92,17.59s15.08,11.1,22.79,16.42c3.86,2.66,7.74,5.28,11.57,8s7.63,5.42,11.43,8.16c7.59,5.48,15.19,10.94,22.67,16.57s14.87,11.38,22.19,17.23,14.58,11.76,21.7,17.88l-.24.32c-7.89-5.07-15.66-10.32-23.34-15.68s-15.29-10.81-22.86-16.32-15-11.28-22.37-17c-3.72-2.85-7.43-5.7-11.1-8.61s-7.3-5.87-11-8.8c-7.3-5.87-14.66-11.66-22.25-17.13s-15.48-10.57-23.14-16q-5.73-4.05-11.32-8.3l-5.59-4.25-5.54-4.32c-7.36-5.79-14.67-11.65-21.72-17.86Z'
        transform='translate(-10.28 -127.33)'
      />
      <path
        className='cls-3'
        d='M726.05,255.49c7.94-1.64,15.93-2.87,23.93-4l6-.81,6-.74c4-.5,8-.9,12-1.23,8.05-.64,16.14-.88,24.18-1.66s16-1.88,24-3.11c4-.62,8-1.28,12-1.85s8-1.1,12-1.61c8-1,16-2.08,24-2.92s16.08-1.54,24.14-2.11,16.12-1.06,24.22-1.3l0,.39c-7.95,1.54-15.92,2.86-23.92,4s-16,2.24-24,3.23-16.06,1.66-24.1,2.41c-4,.38-8,.76-12.06,1.06s-8.06.55-12.09.83c-8.07.55-16.12,1.19-24.13,2.23s-16,2.54-24,3.68c-4,.56-8,1.05-12,1.44l-6,.59-6,.52c-8.05.64-16.11,1.2-24.22,1.33Z'
        transform='translate(-10.28 -127.33)'
      />
      <path
        className='cls-3'
        d='M267.7,369.87c12.08.36,24.13,1.14,36.16,2l9,.69,9,.77q9,.75,18,1.78l18,2.23,9,1.13,9,1c12,1.27,24,2.14,36.08,2.91,6,.39,12.05.73,18.07,1.17s12,.9,18.05,1.4c12,1,24.05,1.93,36.06,3.1,24,2.4,48,5.14,71.91,8.63l0,.4c-24.14-1-48.22-2.71-72.26-4.78-12-1.07-24-2.35-36-3.61-6-.62-12-1.25-18-1.95s-12-1.46-18-2.18c-12-1.47-24-2.83-36-3.8l-9-.67-9-.54-18.07-1.13q-9-.66-18-1.57l-9-.91-9-1c-12-1.37-24-2.82-35.92-4.7Z'
        transform='translate(-10.28 -127.33)'
      />
      <path
        className='cls-3'
        d='M559,400c-5.66,5.29-11.58,10.25-17.55,15.13l-4.5,3.64-4.55,3.57q-4.54,3.57-9.25,6.94c-6.28,4.49-12.8,8.66-19,13.25S491.92,451.93,486,456.9c-3,2.47-5.89,5-8.87,7.43s-6,4.85-9,7.25c-6,4.79-12.07,9.61-18.22,14.26s-12.4,9.18-18.72,13.61-12.69,8.8-19.21,13l-.24-.32c5.73-5.2,11.59-10.23,17.54-15.15s12-9.74,18-14.5,12.32-9.28,18.53-13.86c3.1-2.29,6.21-4.58,9.36-6.81s6.33-4.41,9.49-6.62c6.34-4.41,12.62-8.9,18.65-13.71s11.78-10,17.75-14.86q4.48-3.66,9.13-7.12l4.63-3.45,4.69-3.4c6.27-4.49,12.6-8.91,19.19-13Z'
        transform='translate(-10.28 -127.33)'
      />
      <path
        className='cls-3'
        d='M267.82,370.43c6.46,5.36,12.62,11,18.72,16.75l4.55,4.32,4.49,4.37c3,2.91,5.93,5.9,8.81,8.93,5.75,6.08,11.21,12.44,17.05,18.42s11.92,11.74,18.09,17.39c3.08,2.83,6.19,5.63,9.24,8.49s6.07,5.75,9.08,8.65c6,5.81,12.06,11.59,17.95,17.53s11.68,12,17.37,18.1,11.34,12.31,16.8,18.67l-.28.29c-6.38-5.44-12.61-11-18.74-16.73s-12.17-11.48-18.16-17.31S361,466.42,355.2,460.42c-2.92-3-5.83-6-8.69-9.05s-5.69-6.14-8.53-9.21c-5.68-6.15-11.42-12.23-17.45-18s-12.39-11.26-18.49-17c-3-2.87-6-5.79-9-8.78l-4.39-4.47-4.34-4.53c-5.74-6.08-11.43-12.22-16.81-18.66Z'
        transform='translate(-10.28 -127.33)'
      />
      <path
        className='cls-3'
        d='M387.08,280.49c7.67,4.2,15.09,8.77,22.47,13.4l5.51,3.51,5.47,3.58q5.48,3.56,10.79,7.35c7.1,5.05,14,10.44,21.15,15.38s14.53,9.6,22,14.16c3.71,2.28,7.45,4.52,11.14,6.84s7.35,4.67,11,7c7.31,4.73,14.64,9.44,21.85,14.33s14.34,9.88,21.4,15,14.07,10.29,20.95,15.68l-.22.33q-11.4-6.46-22.48-13.39c-7.42-4.58-14.73-9.31-22-14.07s-14.41-9.78-21.57-14.74q-5.39-3.71-10.71-7.49c-3.55-2.53-7-5.13-10.57-7.69-7-5.14-14.14-10.19-21.46-14.91s-14.9-9-22.28-13.68c-3.68-2.33-7.33-4.71-10.91-7.18l-5.39-3.69-5.35-3.76c-7.1-5.05-14.15-10.17-21-15.65Z'
        transform='translate(-10.28 -127.33)'
      />
      <path
        className='cls-3'
        d='M166.75,359.47a99.2,99.2,0,0,1,12.19,0c2,.11,4,.23,6,.42s4,.42,6,.76c4,.7,7.93,1.77,11.93,2.32a95.68,95.68,0,0,0,12.09.85c2,.05,4.06.05,6.08.15s4,.22,6,.36c4,.29,8.06.56,12.06,1.06a180.3,180.3,0,0,1,23.88,4.52l-.05.4a180.34,180.34,0,0,1-24.29-.67c-4-.37-8-1-12-1.55-2-.29-4-.58-6-.93s-4-.77-6-1.16a97.75,97.75,0,0,0-12-1.75c-4-.31-8.1-.1-12.15-.28q-3-.14-6-.54c-2-.24-4-.55-6-.88a97,97,0,0,1-11.9-2.64Z'
        transform='translate(-10.28 -127.33)'
      />
      <path
        className='cls-3'
        d='M206.48,243.75c3.24,4.81,6.1,9.82,8.88,14.86,1.38,2.53,2.75,5.07,4,7.64s2.58,5.14,3.74,7.78c2.33,5.27,4.31,10.71,6.76,15.92s5.18,10.28,8.07,15.27c1.43,2.5,2.9,5,4.29,7.51s2.75,5.06,4.1,7.6c2.68,5.09,5.39,10.18,7.89,15.36,4.94,10.4,9.65,20.91,13.61,31.8l-.36.17c-6.27-9.74-11.8-19.84-17.08-30.07-2.61-5.13-5-10.36-7.45-15.58-1.21-2.61-2.43-5.22-3.58-7.86s-2.25-5.31-3.38-8c-2.22-5.32-4.6-10.57-7.27-15.67s-5.8-10-8.59-15q-2.09-3.78-3.93-7.68c-1.26-2.59-2.46-5.21-3.63-7.84-2.34-5.27-4.59-10.57-6.47-16.07Z'
        transform='translate(-10.28 -127.33)'
      />
      <ellipse
        className='cls-3'
        cx='551.43'
        cy='267.63'
        rx='15.06'
        ry='15.61'
      />
      <ellipse className='cls-3' cx='379.45' cy='155.68' rx='9.55' ry='9.9' />
      <ellipse
        className='cls-4'
        cx='399.56'
        cy='384.86'
        rx='11.03'
        ry='11.43'
      />
      <ellipse className='cls-3' cx='716.82' cy='128.36' rx='8.32' ry='8.63' />
      <ellipse className='cls-4' cx='196.03' cy='117.55' rx='7.39' ry='7.65' />
      <g className='cls-5'>
        <ellipse
          className='cls-6'
          cx='196.03'
          cy='117.55'
          rx='13.46'
          ry='13.95'
        />
      </g>
      <ellipse className='cls-3' cx='155.84' cy='232.34' rx='6.27' ry='6.5' />
      <path
        className='cls-3'
        d='M20.51,468.18c10-4.73,20.13-9.05,30.31-13.3l7.64-3.15,7.67-3.08q7.66-3.07,15.44-5.92l15.6-5.5,7.8-2.74,7.76-2.85c10.32-3.87,20.5-8.12,30.63-12.46,5.07-2.16,10.13-4.37,15.21-6.49s10.19-4.21,15.3-6.28c10.22-4.14,20.42-8.31,30.7-12.28,20.59-7.87,41.31-15.43,62.3-22.28l.14.37c-20.07,9.21-40.42,17.7-60.88,25.89-10.25,4.06-20.57,7.92-30.89,11.8-5.15,1.95-10.31,3.89-15.5,5.77s-10.39,3.69-15.58,5.54c-10.39,3.69-20.75,7.47-31,11.63L105.57,436,98,439.3l-15.2,6.53Q75.15,449,67.42,452L59.7,454.9l-7.76,2.87c-10.35,3.78-20.74,7.48-31.28,10.78Z'
        transform='translate(-10.28 -127.33)'
      />
      <ellipse className='cls-4' cx='10.31' cy='341.03' rx='10.31' ry='10.68' />
      <path
        className='cls-3'
        d='M903.64,379.16q20.73-5.43,41.6-10.24c13.92-3.18,27.84-6.34,41.81-9.26l10.5-2.12,10.52-2,21-4c14-2.66,28-5.66,41.9-8.84l.42-.09.29.34c11.5,13.14,23.2,26.1,34.63,39.29,5.75,6.57,11.42,13.21,17.13,19.81s11.42,13.2,17,19.87q17,19.95,33.51,40.25c11,13.52,22,27.11,32.75,40.88l-.31.26c-11.83-12.85-23.44-25.89-35-39s-23-26.31-34.23-39.64c-5.66-6.64-11.22-13.36-16.81-20.07s-11.2-13.39-16.74-20.13c-11.15-13.44-22-27.1-33.11-40.59l.71.25c-14,2.81-28,5.8-41.84,9.12l-20.83,5-10.41,2.49-10.44,2.37c-13.94,3.07-27.94,5.9-41.93,8.7s-28,5.36-42.14,7.72Z'
        transform='translate(-10.28 -127.33)'
      />
      <path
        className='cls-3'
        d='M898.77,383.88c-4.95,6.9-10.22,13.51-15.56,20.07l-4,4.9-4.09,4.84c-2.72,3.24-5.52,6.41-8.37,9.53-5.72,6.23-11.74,12.2-17.35,18.53s-11,12.85-16.25,19.48c-2.63,3.31-5.23,6.65-7.9,9.93s-5.37,6.53-8.08,9.77c-5.43,6.49-10.83,13-16.39,19.36s-11.24,12.64-17,18.82-11.6,12.32-17.62,18.3l-.3-.27c5-6.82,10.24-13.5,15.54-20.09s10.7-13.09,16.16-19.55,11.14-12.72,16.77-19c2.81-3.15,5.63-6.3,8.5-9.41s5.78-6.17,8.68-9.26c5.79-6.17,11.5-12.41,16.91-18.9S858.86,427.6,864.2,421c2.67-3.27,5.4-6.5,8.21-9.66l4.2-4.75,4.25-4.7c5.72-6.24,11.5-12.41,17.61-18.31Z'
        transform='translate(-10.28 -127.33)'
      />
      <ellipse className='cls-4' cx='755.69' cy='409.94' rx='6.95' ry='7.2' />
      <ellipse className='cls-4' cx='1197.73' cy='375.86' rx='6.95' ry='7.2' />
      <path
        className='cls-3'
        d='M1074,342.76a133.79,133.79,0,0,1,12.12-7.64c2.07-1.18,4.15-2.33,6.26-3.43s4.24-2.16,6.42-3.12c4.36-1.92,8.89-3.49,13.19-5.53a134.56,134.56,0,0,0,12.53-6.84c2-1.23,4.07-2.5,6.13-3.68s4.15-2.33,6.24-3.47c4.18-2.28,8.35-4.57,12.62-6.67a248.71,248.71,0,0,1,26.34-11.15l.18.36a251.73,251.73,0,0,1-24.59,14.61c-4.22,2.2-8.53,4.2-12.84,6.22-2.16,1-4.31,2-6.5,3s-4.39,1.84-6.59,2.76a134.69,134.69,0,0,0-12.94,6c-4.18,2.26-8.14,5-12.26,7.36-2.07,1.19-4.18,2.29-6.32,3.32s-4.3,2-6.47,3a132.82,132.82,0,0,1-13.34,5.24Z'
        transform='translate(-10.28 -127.33)'
      />
      <ellipse className='cls-3' cx='1165.7' cy='164.07' rx='6.95' ry='7.2' />
      <ellipse className='cls-3' cx='1061.39' cy='215.61' rx='6.68' ry='6.92' />
      <path
        className='cls-3'
        d='M258.28,370.07a9.33,9.33,0,0,1,9.16-9.49,9.5,9.5,0,1,1,0,19A9.33,9.33,0,0,1,258.28,370.07Z'
        transform='translate(-10.28 -127.33)'
      />
      <ellipse
        className='cls-3'
        cx='888.34'
        cy='256.42'
        rx='12.13'
        ry='12.57'
      />
      <ellipse className='cls-6' cx='908.38' cy='107' rx='6.68' ry='6.92' />
      <text
        className='cls-2'
        transform='translate(120.81 74.24)'
        id='quality'
        onMouseOver={showDesc}
        onMouseOut={hideDesc}
      >
        Quality
      </text>
      <text className='cls-1' transform='translate(256.66 430.41)'>
        <tspan className='reliability-desc'>We sustain a strong sense of</tspan>
        <tspan className='reliability-desc' x='0' y='24.94'>
          professionalism as we build
        </tspan>
        <tspan className='reliability-desc' x='0' y='49.89'>
          and maintain solid relatonships
        </tspan>
        <tspan className='reliability-desc' x='0' y='74.83'>
          with our clients.
        </tspan>
      </text>
      <text className='cls-1' transform='translate(633.9 442.97)'>
        <tspan className='integrity-desc'>We do business with complete</tspan>
        <tspan className='integrity-desc' x='0' y='24.94'>
          honesty and trust.
        </tspan>
      </text>
      <text className='cls-1' transform='translate(1060.96 413.61)'>
        <tspan className='ethics-desc'>We are commited to morality,</tspan>
        <tspan className='ethics-desc' x='0' y='24.94'>
          fairness, and respect, both
        </tspan>
        <tspan className='ethics-desc' x='0' y='49.89'>
          internally and externally.
        </tspan>
      </text>
      <text className='cls-1' transform='translate(793.47 54.76)'>
        <tspan className='sustain-desc'>We are constantly improving the</tspan>
        <tspan className='sustain-desc' x='0' y='24.94'>
          efciency and sustainability of our products.
        </tspan>
      </text>
      <text className='cls-1' transform='translate(0 390.57)'>
        <tspan className='excellence-desc'>
          We exceed customers’ expectations
        </tspan>
        <tspan className='excellence-desc' x='0' y='24.94'>
          through elevated services
        </tspan>
        <tspan className='excellence-desc' x='0' y='49.89'>
          and timely deliveries.
        </tspan>
      </text>
    </svg>
  )
}

export default CorporateSVG
