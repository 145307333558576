import { motion } from 'framer-motion'
import React from 'react'
import { Form, FormGroup } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import './Form.css'
import * as FiIcons from 'react-icons/fi'

const RequestForm = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const onSubmit = (data, e) => {
    e.target.reset()
    console.log(watch())
  }
  return (
    <Form
      className='request-form d-flex justify-content-center'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='w-50'>
        <h1 className='py-3'>Request a Quote</h1>
        <FormGroup controlId='formName'>
          <Form.Control
            {...register('name', { required: true })}
            type='text'
            placeholder='Full Name'
          />
        </FormGroup>
        <FormGroup controlId='formCompany'>
          <Form.Control
            {...register('company', { required: true })}
            type='text'
            placeholder='Company'
          />
        </FormGroup>
        <FormGroup controlId='formPosition'>
          <Form.Control
            {...register('position', { required: true })}
            type='text'
            placeholder='Position'
          />
        </FormGroup>
        <FormGroup controlId='formEmail'>
          <Form.Control
            {...register('email', { required: true })}
            type='text'
            placeholder='Email'
          />
        </FormGroup>
        <FormGroup controlId='formWebsite'>
          <Form.Control
            {...register('website', { required: true })}
            type='text'
            placeholder='Website'
          />
        </FormGroup>
        <FormGroup controlId='formMobile'>
          <Form.Control
            {...register('mobile', { required: true })}
            type='number'
            placeholder='Mobile'
          />
        </FormGroup>
        <FormGroup controlId='formCountry'>
          <Form.Control
            {...register('country', { required: true })}
            type='number'
            placeholder='Country'
          />
        </FormGroup>
        <FormGroup controlId='formRequirements'>
          <Form.Control
            as='textarea'
            {...register('requirements', { required: true })}
            style={{ height: '85px', paddingTop: '20px' }}
            placeholder='Requirements'
          />
        </FormGroup>
        <FormGroup
          style={{ paddingLeft: '5%' }}
          className='d-flex align-items-center'
        >
          <FiIcons.FiPlus color='white' />
          <p style={{ color: 'white', marginBottom: '0', paddingLeft: '3%' }}>
            Please Share Reference Catalog
          </p>
        </FormGroup>
        {errors.name && errors.email && errors.mobile && (
          <p>These Fields is required!</p>
        )}
        <motion.button
          type='submit'
          whileHover={{
            scale: 1.1,
          }}
          whileTap={{ scale: 0.8 }}
          className='talent-button'
        >
          SEND
        </motion.button>
      </div>
    </Form>
  )
}

export default RequestForm
